import { action, computed, observable } from 'mobx'
import { create, persist } from 'mobx-persist'
import { Singleton } from 'typescript-ioc/es5'
import { SidebarState } from './sidebar'
import { fetchAllDomains, fetchDomain, fetchFlags, setCurrentDomainSession } from '../_utils/utils'
import { DomainDto } from '../dtos/domain'
import { UserDto } from '../dtos/user'
import { PlatformMessageDto } from '../dtos/platform-message'
import { synchronizeDomainStates, ISynchronizedDomainStateResponse } from '../_utils/domain'
import { Error404 } from '../exceptions/error-404'
import { AppActionContext } from '../enums/app-action-context'
import { WhitelabelConfigModel } from '../models/whitelabel-config/whitelabel-config.model'
import * as Cookie from 'cookie'
import aqe from '@pushly/aqe'
import FlagList from '../structs/flags-list'
import { AbilityStore } from './app-ability'
import { addVisibilityAwareIntervalRunner } from '../_utils/visibility-api'
import { PlatformUserDomainRecord } from '../models/domain/platform-user-domain-record'
import { Expose } from 'class-transformer'

@Singleton
export class AppState {
    @observable
    public abilityStore: AbilityStore

    @observable
    public loadingCount: any = 1

    @observable
    public moduleLoadingCount: number = 0

    @observable
    public showing404Count: number = 0

    public get moduleLoading(): boolean {
        return this.moduleLoadingCount !== 0
    }

    @persist
    @observable
    public isAuthenticated: boolean = false

    @persist
    @observable
    public locale: string = 'en'

    public token?: string = undefined

    @observable
    public sidebar: SidebarState

    @observable
    public platformMessages: PlatformMessageDto[] = []

    public flags: FlagList = FlagList.from([])

    @persist
    @observable
    public userPlatformMessages: any

    @persist
    @observable
    public notifListSourceFilters: string

    @persist
    @observable
    public notifListStatusFilters: string

    @persist
    @observable
    public appMessageListChannelFilters: string

    @persist
    @observable
    public appMessageListStatusFilters: string

    @persist
    @observable
    public actionContext: AppActionContext

    @persist
    @observable
    public notifChannelSelection: string

    @observable
    public usingNewNotifUx: boolean = true

    @observable
    public currentUser?: UserDto

    @observable
    public currentUserDomains?: PlatformUserDomainRecord[]

    @persist
    @observable
    public currentDomainJsonData?: any // persisted items have to be primitives

    @observable
    public whiteLabelSettings?: WhitelabelConfigModel

    public isVersionCurrent: boolean = true

    private userDependencyFetcher: Promise<void> | undefined

    @computed
    public get platformDisplayName(): string {
        return this.whiteLabelSettings?.getDisplayName() ?? 'Pushly'
    }

    @computed
    public get documentationLink(): string {
        return this.whiteLabelSettings?.getDocumentationLink() ?? 'https://documentation.pushly.com'
    }

    @computed
    public get platformHost(): string {
        const hostname =
            this.whiteLabelSettings?.getHostname() ?? aqe.defaults.publicPlatformDomain?.replace(/^http[s]?:\/\//i, '')

        return `https://${hostname}`
    }

    public get currentDomain(): DomainDto | undefined {
        return this.currentDomainJsonData
            ? DomainDto.fromApiResponse(JSON.parse(this.currentDomainJsonData))
            : this.currentDomainJsonData
    }

    @computed
    public get appIsLoading(): boolean {
        return this.loadingCount !== 0
    }

    @computed
    public get inOrgContext(): boolean {
        return this.actionContext === AppActionContext.ORG
    }

    @computed
    public get inDomainContext(): boolean {
        return this.actionContext === AppActionContext.DOMAIN
    }

    private rehydrater: Promise<any> | undefined
    private userDependencyFetchIntervalRunnerAdded = false

    public constructor() {
        this.sidebar = new SidebarState()
        this.abilityStore = new AbilityStore(this)
        this.initializer
    }

    public get initializer() {
        return this.rehydrate()
    }

    @action.bound
    public async fetchUserDependencies() {
        if (!this.userDependencyFetcher) {
            this.userDependencyFetcher = new Promise(async (res, rej) => {
                try {
                    await fetchFlags().then((flags) => (this.flags = flags))

                    const domainData = await fetchAllDomains({
                        query: {
                            fields: [
                                'id',
                                'name',
                                'display_name',
                                'account_id',
                                'account_name',
                                'account_flags',
                                'timezone',
                                'flags',
                                'default_icon_url',
                            ],
                        },
                    })
                    this.currentUserDomains = domainData.data

                    let initialDomainId: number

                    if (!this.currentDomainJsonData) {
                        // No previous data exists - load first available domain
                        initialDomainId = this.currentUserDomains![0].id
                    } else if (this.currentDomain) {
                        // Attempt to validate previous domain data
                        // and fallback to first available domain
                        initialDomainId =
                            this.currentUserDomains!.find((domain) => domain.id === this.currentDomain!.id)?.id ??
                            this.currentUserDomains![0].id
                    } else {
                        // Fallback - load first available domain
                        initialDomainId = this.currentUserDomains![0].id
                    }

                    const initialDomain = await fetchDomain(initialDomainId)
                    this.currentDomainJsonData = JSON.stringify(initialDomain)
                } catch (err) {
                    rej(err)
                    return
                }

                this.setupUserDependencyFetchRunner()

                res()

                this.userDependencyFetcher = undefined
            })
        }

        return this.userDependencyFetcher
    }

    private setupUserDependencyFetchRunner() {
        if (!this.userDependencyFetchIntervalRunnerAdded) {
            this.userDependencyFetchIntervalRunnerAdded = true

            addVisibilityAwareIntervalRunner(
                'user-dependencies',
                async () => {
                    await this.fetchUserDependencies()
                },
                300000,
            )
        }
    }

    private async rehydrate() {
        if (!this.rehydrater) {
            this.rehydrater = new Promise<void>(async (res, rej) => {
                try {
                    const hydrate = create({
                        storage: localStorage,
                        jsonify: true,
                    })

                    await hydrate('pufferfish', this)
                    await hydrate('pufferfish.sidebar-v2', this.sidebar)

                    this.removeLegacyToken()
                } catch (error) {
                    rej(error)

                    return
                }

                /**
                 * Ensures proper domain pull order after hydration
                 * priority:
                 *  1. path specified domain
                 *  2. session domain (sessionStorage)
                 *  3. hydrated domain (localStorage)
                 *
                 * Application actionContext is also set based on req
                 * path of "/organzations/" or "/domains/".
                 *
                 * If an org path is requested the syncRes domain
                 * will be validated against the req orgID below
                 * after the user dependencies have been fully loaded.
                 */
                let syncRes: ISynchronizedDomainStateResponse | undefined
                try {
                    syncRes = await synchronizeDomainStates()
                    this.actionContext = syncRes.context
                    this.currentDomainJsonData = JSON.stringify(syncRes.domain)

                    if (syncRes.error instanceof Error404) {
                        this.showing404Count += 1
                    }
                } catch (err) {
                    console.warn('Domain Synchronization Error', err)
                }

                try {
                    if (this.isAuthenticated) {
                        /**
                         * Synchronized domains can be out of scope when
                         * the initial req path is an organization.
                         *
                         * After user dependencies (this.currentUserDomains) have
                         * been loaded - validate synchronized domain against req orgId.
                         *
                         * If domain is not in the requested org use the org's first
                         * listed domain as the currentDomain to initialize this session.
                         */
                        if (syncRes?.reqIds.org && syncRes.domainNotInRequestedOrg) {
                            const syncDomainOverride = this.currentUserDomains?.find(
                                (d) => d.accountId.toString() === syncRes!.reqIds.org!.toString(),
                            )
                            if (syncDomainOverride) {
                                // load complete domain
                                const domainOverride = await fetchDomain(syncDomainOverride.id)
                                // udpate app context
                                this.currentDomainJsonData = domainOverride
                                // udpate current session
                                setCurrentDomainSession(domainOverride)
                            }
                        }
                    }
                } catch (_error) {
                    // pass
                }

                res()
            })
        }

        return this.rehydrater
    }

    private removeLegacyToken() {
        try {
            const cookies = Cookie.parse(document.cookie)
            if ('swptk' in cookies) {
                const now = new Date()
                const yesterday = now.setDate(now.getDate() - 1)
                document.cookie = `swptk=null;path=/;domain=${
                    aqe.defaults.storageDomain
                };expires=${yesterday.toString()};max-age=0;`
            }
        } catch (_) {}
    }
}
