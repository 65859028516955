import { INotificationAction } from '../interfaces/notification-action'
import { NotificationDto } from '../features/notifications/index'
import { NotificationAutoSuggestFields } from '../enums/notification-auto-suggest-fields.enum'
import { EcommItemType } from '../components/campaign-builder/enums'
import { SubjectEntity } from '../enums/ability-entity.enum'
import { ICaslSubject } from '../stores/app-ability'
import { IAddedNotificationQueryParam } from '../interfaces/added-notification-query-param'
import {
    IAbstractNativeApnsSendIntegration,
    IAbstractNativeFcmSendIntegration,
    IWebApnsSendIntegration,
} from '../interfaces/send-integrations'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS, FEAT_CHANNEL_WEB } from '../constants'
import { FlagModel } from '../models/domain/flag.model'
import { AppState } from '../stores/app'
import { App } from '../app'

export interface IDomainUtmParams {
    utm_source: string
    utm_medium?: string
    utm_campaign?: string
    utm_term?: string
    utm_content?: string
}

export type DomainFrequencyCapMetric = 'days' | 'hours' | 'minutes'

export interface IDomainFrequencyCap {
    occurrences: number
    fcapSeconds: number
    displayMetric: DomainFrequencyCapMetric
}

export interface IDomainFrequencyCaps {
    prompt?: IDomainFrequencyCap
    notification?: IDomainFrequencyCap
}

export class DomainKeyword {
    public readonly id: number
    public readonly name: string
}

export class DomainPageTag {
    public readonly id: number
    public readonly name: string
}

export class EcommItemGroup {
    public readonly id: number
    public readonly name: string
}

export enum PromptReferrerAllowType {
    ALLOW = 'allow',
    IGNORE = 'ignore',
    BLOCK = 'block',
}

export interface IPromptReferrerUrlsConfig {
    exact?: string[]
    contains?: string[]
}

export interface IGlobalPromptReferrerConditions {
    enabled: boolean
    persistTtlSeconds: number
    whenSelf: PromptReferrerAllowType
    whenBlank: PromptReferrerAllowType
    allowed?: IPromptReferrerUrlsConfig
    blocked?: IPromptReferrerUrlsConfig
}

export interface IDomainEcommConfig {
    clickAttributionWindowHours?: number
    impressionAttributionWindowHours?: number
    maxAbandonedItemAgeDays?: number
    itemType?: EcommItemType
}

export interface IDomainGlobalPromptSettings {
    referrers?: IGlobalPromptReferrerConditions
}

export class DomainDto implements ICaslSubject<SubjectEntity.DOMAIN> {
    public static fromApiResponse(fields: Partial<DomainDto>): DomainDto {
        const dto = new DomainDto()
        Object.assign(dto, fields)

        if (dto.fallbackNotificationTemplate) {
            try {
                dto.fallbackNotificationTemplate = NotificationDto.parse(dto.fallbackNotificationTemplate)
            } catch (err) {
                console.warn('Invalid fallback notification template', dto.fallbackNotificationTemplate)
                console.error(err)
            }
        }

        return dto
    }

    public __caslSubjectType__ = SubjectEntity.DOMAIN

    public readonly id: number
    public accountName: string
    public accountId: number
    public name: string // DOMAIN NAME - eg: seaworld.com
    public displayName: string // DISPLAY NAME - eg: Seaworld
    public timezone: string
    public currencyCode: string
    public currency?: {
        code: string
        name: string
    }
    public domainKey: string
    public statusId: number
    public status: string
    public senderId: string
    public fcmServerKey: string
    public vapidPublicKey: string
    public defaultIconUrl: string
    public defaultBadgeUrl: string
    public defaultNotificationTtlSeconds: number
    public globalPromptSettings?: IDomainGlobalPromptSettings
    public ecommConfig?: IDomainEcommConfig
    public welcomeNotificationEnabled: boolean
    public utmParams: IDomainUtmParams
    public flags: string[] = []
    public accountFlags: string[] = []
    public frequencyCaps: IDomainFrequencyCaps
    public createdAt: Date
    public updatedAt: Date
    public apnsConfiguration: IWebApnsSendIntegration
    public nativeApnsConfiguration: IAbstractNativeApnsSendIntegration
    public nativeFcmConfiguration: IAbstractNativeFcmSendIntegration
    public whitelistDomains: string[]
    public sdkEventOnlyDomains: string[]
    public landingUrlDomainWhitelist: string[]
    public mediaQuality?: number
    public displayMeta: any
    public defaultNotificationActionLabels: any = {}
    public defaultNotificationActions: INotificationAction[] = []
    public fallbackNotificationTemplateId: string
    public fallbackNotificationTemplate: any
    public notificationAutoSuggestFields: NotificationAutoSuggestFields[] | null

    public addedNotificationQueryParams: { [key: string]: IAddedNotificationQueryParam }
    public collapseKeyType: string
    public routingKey: string

    public readonly createdBy: number
    public readonly updatedBy: number
    public createdByUsername: string
    public updatedByUsername: string

    public readonly prodDomainId: number

    public get isDemoDomain(): boolean {
        return this.flags?.includes('DEMO') ?? false
    }

    public get isInternal(): boolean {
        return (this.flags?.includes('INTERNAL') ?? false) || (this.accountFlags?.includes('INTERNAL') ?? false)
    }

    public get isPreviewDomain(): boolean {
        return this.flags?.includes('PREVIEW_DOMAIN') ?? false
    }

    public get isInternalAccountDomain(): boolean {
        return this.accountFlags?.includes('INTERNAL') ?? false
    }

    public isWebEnabled(appState?: AppState): boolean {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_WEB
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_WEB)
    }

    public isIOSEnabled(appState?: AppState): boolean {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_IOS
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_IOS) && this.nativeApnsConfiguration?.isActive
    }

    public isAndroidEnabled(appState?: AppState): boolean {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_ANDROID
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_ANDROID) && this.nativeFcmConfiguration?.isActive
    }
}
