import { AbstractStruct } from '@pushly/models/lib/structs/abstract-struct'
import { Expose } from 'class-transformer'

export class PlatformUserDomainRecord extends AbstractStruct {
    @Expose()
    public readonly id: number

    @Expose()
    public readonly name: string

    @Expose()
    public readonly displayName: string

    @Expose()
    public readonly accountId: number

    @Expose()
    public readonly accountName: string

    @Expose()
    public readonly accountFlags: string[]

    @Expose()
    public readonly timezone: string

    @Expose()
    public readonly flags: string[]

    @Expose()
    public readonly defaultIconUrl: string
}
