import React from 'react'
import './style/active-domain-select.scss'
import classnames from 'classnames'
import { Avatar, Input, List, Popover } from 'antd'
import { StickyTree } from 'react-virtualized-sticky-tree'
import { ArrowRightOutlined, GlobalOutlined, SearchOutlined } from '@ant-design/icons'
import { AppActionContext } from '../../enums/app-action-context'
import { observer } from 'mobx-react'
import IconOrgFilled from '../icons/icon-org-filled'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { DomainDto } from '../../dtos/domain'
import { Container } from 'typescript-ioc/es5'
import { escapeRegExp, preventAll, preventBubbling } from '../../_utils/utils'
import { FEAT_MULTI_DOMAIN_NOTIFICATIONS } from '../../constants'
import { AccessRoleId } from '../../enums/access-role.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { getPathClassName } from '../../_utils/get-path-classname'

const LIST_WIDTH = 272
const DOMAIN_ITEM_HEIGHT = 60
const ORG_ITEM_HEIGHT = 42
const MAX_LIST_HEIGHT = 366
const MIN_LIST_HEIGHT = 120
const ALLOWED_INTERACTION_KEYS = ['escape']

interface IState {
    filter?: string
    visible?: boolean
    activeIndex: number
}

@observer
class ActiveDomainSelect extends React.Component {
    public state: IState = {
        activeIndex: -1,
    }

    private readonly appState: AppState
    private readonly appService: AppService
    private domainTree: any = {}

    private stickyTreeRef: any
    private searchRef: any
    private popoverRef: any

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount() {
        document.addEventListener('keydown', this.handleKeyboardInteraction)
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyboardInteraction)
    }

    public render() {
        const { currentDomain } = this.appState
        const { visible } = this.state

        const [domainTree, treeHeight] = this.getDomainTree()
        const nonRootNodes = Object.keys(domainTree).filter((key) => key !== 'root')
        const height =
            treeHeight < MIN_LIST_HEIGHT ? MIN_LIST_HEIGHT : treeHeight > MAX_LIST_HEIGHT ? MAX_LIST_HEIGHT : treeHeight

        return (
            <div
                className={classnames('active-domain-select', {
                    'domain-context': this.appState.inDomainContext,
                    'org-context': this.appState.inOrgContext,
                    'single-domain': nonRootNodes.length === 1,
                })}
            >
                <Popover
                    ref={(el) => (this.popoverRef = el)}
                    overlayClassName={classnames('active-domain-select-overlay', {
                        'domain-context': this.appState.inDomainContext,
                        'org-context': this.appState.inOrgContext,
                    })}
                    trigger="click"
                    placement="leftTop"
                    visible={visible}
                    onVisibleChange={this.handleVisibleChange}
                    content={
                        <>
                            <List.Item className={classnames('active-domain-search-wrapper')}>
                                <Input
                                    ref={(el) => (this.searchRef = el)}
                                    key={visible ? 'open' : 'collapsed'}
                                    className={classnames('active-domain-search')}
                                    size="small"
                                    placeholder={currentDomain?.displayName}
                                    suffix={<SearchOutlined />}
                                    onChange={(ev) => {
                                        const search = ev.target.value.trim()
                                        return this.setState({ filter: search })
                                    }}
                                />
                            </List.Item>

                            <StickyTree
                                ref={(el) => (this.stickyTreeRef = el)}
                                root={domainTree.root}
                                width={LIST_WIDTH}
                                height={height}
                                getChildren={this.getChildren}
                                rowRenderer={this.rowRenderer}
                                renderRoot={false}
                                overscanRowCount={20}
                            />
                        </>
                    }
                >
                    <List.Item
                        className={classnames('active-domain-select-item', 'active-domain-select-item-selected')}
                    >
                        {this.appState.inOrgContext ? (
                            <List.Item.Meta
                                title={currentDomain?.accountName}
                                description="Organization"
                                avatar={<Avatar icon={<IconOrgFilled />} gap={2} />}
                            />
                        ) : (
                            <List.Item.Meta
                                title={currentDomain?.displayName}
                                description={currentDomain?.name}
                                avatar={
                                    <Avatar
                                        {...(currentDomain?.defaultIconUrl
                                            ? { src: currentDomain.defaultIconUrl }
                                            : { icon: <GlobalOutlined />, gap: 2 })}
                                    />
                                }
                            />
                        )}
                    </List.Item>
                </Popover>
            </div>
        )
    }

    /**
     * The domainTree designates the structure of the selector dropdown
     *  root (UI ignored) -> tier 1 (org | domain) -> tier 2 (domain | null)
     *
     * Visiblity rules:
     *  Internal Users  - root -> all orgs -> domains
     *  Single Domain Users:
     *      !org admin  - no dropdown
     *      org admin   - root -> single org -> single domain
     *  Multi Domain Users:
     *      !org admin  - root -> domains
     *      org admin   - root -> single org -> domains
     */
    protected getDomainTree(): [any, number] {
        const { currentUserDomains, abilityStore } = this.appState
        const { filter } = this.state

        // remove any dev testing domains
        let filteredDomains = currentUserDomains?.filter((d) => d.accountId !== -9) ?? []
        // adjust available domains for search filter
        if (filter) {
            const searchRgx = new RegExp(escapeRegExp(filter), 'i')
            filteredDomains = filteredDomains.filter((d) => {
                return (
                    searchRgx.test(d.id.toString()) ||
                    searchRgx.test(d.name) ||
                    searchRgx.test(d.displayName) ||
                    searchRgx.test(d.accountName)
                )
            })
        }

        // ensure domains are alpha sorted by display name
        filteredDomains.sort((ad: any, bd: any) => {
            const adn = ad.displayName?.toLowerCase()?.replace(/^\(DEMO\)[\s]*(.*)/i, (_m, name) => `${name}x`)
            const bdn = bd.displayName?.toLowerCase()?.replace(/^\(DEMO\)[\s]*(.*)/i, (_m, name) => `${name}x`)
            return adn > bdn ? 1 : adn < bdn ? -1 : 0
        })

        // to prevent possible ID overlap org level uses accountName
        // gather all unique orgs keys for filtered domains
        const orgKeys: string[] = Array.from(new Set(filteredDomains.map((d) => d.accountName)))
        orgKeys.sort((a: string, b: string) =>
            a?.toLowerCase() > b?.toLowerCase() ? 1 : a?.toLowerCase() < b?.toLowerCase() ? -1 : 0,
        )
        // Freeze orgKeys - prevent mutation during loop and throw if attempted
        Object.freeze(orgKeys)

        // initialize tree root with available orgs
        let treeHeight: number = 0
        const domainTree: any = {
            root: {
                id: 'root',
                height: DOMAIN_ITEM_HEIGHT,
                // use clone of orgKeys to prevent mutation below
                children: [...orgKeys],
                isSticky: true,
                top: 0,
                zIndex: 4,
            },
        }

        if (orgKeys.length === 0) {
            treeHeight += ORG_ITEM_HEIGHT

            // when no data is available (search returned 0) show empty node
            domainTree.root.children.push('empty')
            domainTree.empty = {
                id: 'empty',
                name: 'No Domains Found',
                depth: 0,
            }
        } else {
            const roleTypeCheck = this.appState.currentUser?.isInternalUser ? 'internal' : 'external'

            for (const orgKey of orgKeys) {
                const orgDomains = filteredDomains.filter((d) => d.accountName === orgKey)
                const orgId = orgDomains[0]?.accountId
                const orgPerms = abilityStore.acs.getAccountRecord(orgId)

                const noAccessRoleId =
                    roleTypeCheck === 'internal' ? AccessRoleId.INTERNAL_NO_ACCESS : AccessRoleId.EXTERNAL_NO_ACCESS
                const orgRoleId = orgPerms?.getRoleId() ?? noAccessRoleId
                const maxDomainRole = Math.max.apply(
                    null,
                    Array.from(orgPerms?.getDomainRecords().values() ?? []).map(
                        (record) => record.getRoleId() ?? noAccessRoleId,
                    ),
                )

                const orgFlags = orgDomains[0]?.accountFlags ?? []
                const multiDomainFlag = this.appState.flags.findActive(FEAT_MULTI_DOMAIN_NOTIFICATIONS)?.getKey()
                const orgHasMultiDomainFlag = !!multiDomainFlag && orgFlags.includes(multiDomainFlag)
                const canReadOrgMin = abilityStore.can(
                    AbilityAction.READ,
                    asCaslSubject(SubjectEntity.ORG, {
                        id: orgId,
                        statusId: 1,
                    }),
                )
                const canAccessOrg = canReadOrgMin || (orgHasMultiDomainFlag && orgRoleId !== noAccessRoleId)
                const canDisplayOrg =
                    canAccessOrg || orgKeys.length > 1 || (maxDomainRole !== noAccessRoleId && orgDomains.length > 1)

                // set domain tree tier based on visiblity of org
                const domainDepth = canAccessOrg ? 1 : 0

                if (orgDomains.length > 0) {
                    if (!canDisplayOrg) {
                        // remove org from root children
                        // domain added to root below
                        const orgTreeIdx = domainTree.root.children.findIndex((c) => c === orgKey)
                        if (orgTreeIdx !== -1) {
                            domainTree.root.children.splice(orgTreeIdx, 1)
                        }
                    } else {
                        // add org to root children
                        treeHeight += ORG_ITEM_HEIGHT
                        domainTree[orgKey] = {
                            id: orgId,
                            name: orgKey,
                            depth: 0,
                            roleId: orgRoleId,
                            children: [],
                        }
                    }

                    for (const domain of orgDomains) {
                        const domainPerms = abilityStore.acs.getDomainRecord(domain.id)
                        const domainRoleId = domainPerms?.getRoleId() ?? AccessRoleId.EXTERNAL_NO_ACCESS
                        const canAccessDomain = domainRoleId !== AccessRoleId.EXTERNAL_NO_ACCESS

                        if (canAccessDomain) {
                            if (!canDisplayOrg) {
                                // add domain to root children
                                domainTree.root.children.push(domain.id)
                            } else {
                                // add domain to org children
                                domainTree[orgKey].children.push(domain.id)
                            }

                            treeHeight += DOMAIN_ITEM_HEIGHT
                            domainTree[domain.id] = {
                                id: domain.id,
                                name: domain.name,
                                depth: domainDepth,
                                roleId: domainRoleId,
                                domain,
                            }
                        }
                    }
                }
            }
        }

        this.domainTree = domainTree

        return [domainTree, treeHeight]
    }

    /**
     * getChildren takes the current nodes children IDs
     * and maps them to their associated node configurations
     */
    protected getChildren = (id: any) => {
        if (this.domainTree[id]?.children) {
            return this.domainTree[id].children.map((domainId) => {
                const node = this.domainTree[domainId]
                const nodeHeight = Array.isArray(node.children) ? ORG_ITEM_HEIGHT : DOMAIN_ITEM_HEIGHT

                return {
                    id: domainId,
                    height: nodeHeight,
                    isSticky: true,
                    stickyTop: nodeHeight * node.depth,
                    zIndex: 4 - node.depth,
                }
            })
        }
    }

    protected rowRenderer = ({ id, style }: any) => {
        const { abilityStore } = this.appState
        const node = this.domainTree[id]
        const isEmptyNode = node.id === 'empty'
        const isOrgNode = !isEmptyNode && !node.domain
        const isDomainNode = !isEmptyNode && !isOrgNode

        const title = isDomainNode ? node.domain.displayName : node.name
        const description = isEmptyNode ? undefined : isOrgNode ? 'Organization' : node.domain.name

        let avatarProps: any = { icon: <GlobalOutlined />, gap: 2 }
        if (isDomainNode && node.domain.defaultIconUrl) {
            avatarProps = { src: node.domain.defaultIconUrl }
        }

        return (
            <div key={id} style={style}>
                <List.Item
                    className={classnames('active-domain-select-item', {
                        'empty-node': isEmptyNode,
                        'org-node': isOrgNode,
                        disabled: node.roleId === abilityStore.acs.noAccessRoleId,
                    })}
                    onClick={async (ev) => {
                        const isMetaClick = ev?.metaKey ?? false
                        const domainNode: DomainDto = isOrgNode ? this.domainTree[node.children[0]].domain : node.domain

                        if (isMetaClick) {
                            preventAll(ev)
                        } else {
                            this.setState({ visible: false, filter: undefined })
                            await this.appService.setCurrentDomain(domainNode)
                        }

                        const { pathname } = location
                        if (/(^\/$)|(^\/(domains|organizations)\/[\d]+)/i.test(pathname)) {
                            let locationFinal = pathname.replace(/^\/(domains|organizations)\/[\d]+/i, '')
                            const currContextIsOrg = this.appState.inOrgContext
                            const isContextSwap = (currContextIsOrg && !isOrgNode) || (!currContextIsOrg && isOrgNode)

                            /**
                             * route rewriting on context switch:
                             *  if current node is org level:
                             *      w/ flag - notifications
                             *      w/o flag - settings
                             *
                             *  if current node is domain level:
                             *      if admin - keep current route - all allowed
                             *      if !admin and route is root - notifications
                             *
                             *  if swapping contect from org to domain - always back to notifications
                             */
                            const multiDomainFlag = this.appState.flags
                                .findActive(FEAT_MULTI_DOMAIN_NOTIFICATIONS)
                                ?.getKey()
                            const orgHasMDNotifFlag =
                                !!multiDomainFlag && domainNode.accountFlags?.includes(multiDomainFlag)

                            if (isOrgNode && node.roleId !== abilityStore.acs.noAccessRoleId) {
                                locationFinal = '/#settings'
                                if (orgHasMDNotifFlag) {
                                    locationFinal = '/notifications'
                                }
                            } else if (!isOrgNode) {
                                if (isContextSwap) {
                                    locationFinal = '/notifications'
                                } else if (
                                    abilityStore.cannot(
                                        AbilityAction.UPDATE,
                                        asCaslSubject(SubjectEntity.DOMAIN, {
                                            id: domainNode.id,
                                            statusId: domainNode.statusId,
                                        }),
                                    )
                                ) {
                                    const newPathClassName = getPathClassName(locationFinal)

                                    if (newPathClassName === 'root') {
                                        locationFinal = '/notifications'
                                    }
                                }
                            }

                            const nextLocation = `/${isOrgNode ? 'organizations' : 'domains'}/${
                                node.id
                            }${locationFinal}`
                            if (isMetaClick) {
                                preventAll(ev)
                                window.open(`${this.appState.platformHost}${nextLocation}`)
                            } else {
                                this.appService.route(nextLocation)
                            }
                        }

                        if (!isMetaClick) {
                            this.appState.actionContext = isOrgNode ? AppActionContext.ORG : AppActionContext.DOMAIN
                        }
                    }}
                    extra={
                        <span className={classnames('select-item-arrow')}>
                            <ArrowRightOutlined />
                        </span>
                    }
                >
                    <List.Item.Meta
                        title={title}
                        description={description}
                        avatar={isOrgNode ? undefined : <Avatar {...avatarProps} />}
                    />
                </List.Item>
            </div>
        )
    }

    protected handleVisibleChange = (visible: boolean) => {
        let update: any = { visible }
        // reset search
        if (!visible) {
            update.filter = undefined
        }

        this.setState(update)

        if (visible) {
            setTimeout(() => {
                // bugfix for ReactVirtualizedStickyTree scrollTop reset
                this.stickyTreeRef?.forceUpdate()
            }, 50)

            setTimeout(() => {
                // set focus - timeout ensures rendered search component
                this.searchRef?.input?.focus()
            }, 180)
        }
    }

    protected handleKeyboardInteraction = (ev: KeyboardEvent) => {
        if (!ev.key) {
            return
        }
        const key = ev.key.toLowerCase()
        const canProcess = ALLOWED_INTERACTION_KEYS.includes(key) && this.state.visible

        if (canProcess) {
            preventBubbling(ev)

            if (key === 'escape') {
                this.popoverRef?.close()
            }
        }
    }
}

export default ActiveDomainSelect
