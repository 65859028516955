import { action as mobxAction } from 'mobx'
import { Container, Singleton } from 'typescript-ioc/es5'
import { message, notification } from 'antd'
import { DomainDto } from '../dtos/domain'
import { AppState } from '../stores/app'
import {
    arrayContains,
    fetchDomain,
    getCurrentDomainSession,
    getCurrentDomainStorage,
    setCurrentDomainSession,
    titleCase,
} from '../_utils/utils'
import axios from 'axios'
import { PlatformMessageDto } from '../dtos/platform-message'
import * as querystring from 'query-string'
import aqe from '@pushly/aqe'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { addVisibilityAwareIntervalRunner, VisibilityAwareIntervalCleaner } from '../_utils/visibility-api'
import { AccountDto } from '../dtos/account.dto'
import { IUserChannelPreferences } from '../interfaces/user-channel-preference'
import { DEFAULT_USER_PREFERENCES } from '../constants'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DomainChannelConfig, getEnabledDeliveryChannels } from '../_utils/domain'
import { getAccountEnabledDeliveryChannels } from '../_utils/account'
import { PlatformUserDomainRecord } from '../models/domain/platform-user-domain-record'

@Singleton
export class AppService {
    public appContainerRef: any
    public contentContainerRef: any
    public platformMessageAuthRefreshCleaner?: VisibilityAwareIntervalCleaner

    protected historyContainer: any
    protected previousRoute: string
    protected fetchingPlatformMessages: boolean = false

    protected appState: AppState

    public constructor() {
        this.appState = Container.get(AppState)

        message.config({
            top: 52,
            getContainer: this.getAppContainer,
        })
        notification.config({
            top: 52,
            getContainer: this.getAppContainer,
        })
    }

    public set404State() {
        this.appState.showing404Count += 1
    }

    public reset404State() {
        this.appState.showing404Count = 0
    }

    public resetContentScrollPosition(): void {
        if (!!this.contentContainerRef) {
            this.contentContainerRef.scrollTo(0, 0)
        }
    }

    public getDomainDto(id: number) {
        return this.appState.currentUserDomains?.find((d) => d.id === id)
    }

    public getAppContainer = (): HTMLElement => {
        return this.appContainerRef
    }

    public attachHistoryContainer(container: any): void {
        if (!this.historyContainer) {
            this.historyContainer = container
        }
    }

    public get currentRoute(): string {
        return this.historyContainer.location.pathname
    }

    @mobxAction.bound
    public route(newRoute: string, state?: object): void {
        this.previousRoute = this.currentRoute

        if (this.appState.isVersionCurrent) {
            this.historyContainer.push(newRoute, state)
        } else {
            location.href = `${location.origin}/${newRoute.replace(/^\//, '')}`
        }
    }

    @mobxAction.bound
    public routeWithinDomain(newRoute: string, buildOnly?: boolean, state?: object): string {
        if (this.appState.currentDomain) {
            // sanitize and prepend domain
            newRoute = newRoute.replace(/^\/?(?:domain[s]?\/([\d]+\/)?)?/i, '')
            newRoute = `/domains/${this.appState.currentDomain!.id}/${newRoute}`
        }

        if (buildOnly !== true) {
            this.previousRoute = this.currentRoute
            if (this.appState.isVersionCurrent) {
                this.historyContainer.push(newRoute, state)
            } else {
                location.href = `${location.origin}/${newRoute.replace(/^\//, '')}`
            }
        }

        return newRoute
    }

    @mobxAction.bound
    public routeWithin(entity: 'org' | 'domain', newRoute: string, buildOnly?: boolean, state?: object): string {
        const isOrg = entity === 'org'
        const entityPath = isOrg ? 'organizations' : 'domains'

        if (this.appState.currentDomain) {
            const domain = this.appState.currentDomain!
            const entityId = isOrg ? domain.accountId : domain.id

            // sanitize and prepend domain
            newRoute = newRoute.replace(/^\/?(?:(domain|organization)[s]?\/([\d]+\/)?)?/i, '')
            newRoute = `/${entityPath}/${entityId}/${newRoute}`
        }

        if (buildOnly !== true) {
            this.previousRoute = this.currentRoute
            if (this.appState.isVersionCurrent) {
                this.historyContainer.push(newRoute, state)
            } else {
                location.href = `${location.origin}/${newRoute.replace(/^\//, '')}`
            }
        }

        return newRoute
    }

    @mobxAction.bound
    public routeBack(): void {
        const newRoute = this.previousRoute
        if (!newRoute || newRoute === this.currentRoute) {
            this.historyContainer.goBack()
            return
        }
        this.route(newRoute)
    }

    @mobxAction.bound
    public setAppLoading(): void {
        this.appState.loadingCount = this.appState.loadingCount + 1
    }

    @mobxAction.bound
    public unsetAppLoading(): void {
        if (this.appState.loadingCount === 0) {
            return
        }

        this.appState.loadingCount = this.appState.loadingCount - 1
    }

    @mobxAction.bound
    public setModuleLoading(): void {
        this.appState.moduleLoadingCount = this.appState.moduleLoadingCount + 1
    }

    @mobxAction.bound
    public unsetModuleLoading(): void {
        if (this.appState.moduleLoadingCount === 0) {
            return
        }

        this.appState.moduleLoadingCount = this.appState.moduleLoadingCount - 1
    }

    @mobxAction.bound
    public addAndSetCurrentUserDomain(domain: DomainDto) {
        this.appState.currentUserDomains = [
            ...(this.appState.currentUserDomains || []),
            PlatformUserDomainRecord.parseJsonObject(domain),
        ]

        this.appState.currentDomainJsonData = JSON.stringify(domain)
        setCurrentDomainSession(domain)
        this.updateDomainInPlatformPath(domain)
    }

    @mobxAction.bound
    public updateDomain(domain: DomainDto) {
        if (this.appState.currentUserDomains) {
            const index = this.appState.currentUserDomains.findIndex((d) => d.id === domain.id)
            this.appState.currentUserDomains.splice(index, 1, PlatformUserDomainRecord.parseJsonObject(domain))
        }
    }

    @mobxAction.bound
    public removeDomain(domain: DomainDto) {
        if (this.appState.currentUserDomains) {
            const index = this.appState.currentUserDomains.findIndex((d) => d.id === domain.id)
            this.appState.currentUserDomains.splice(index, 1)
        }
    }

    @mobxAction.bound
    public async setCurrentDomain(
        domain: DomainDto | PlatformUserDomainRecord,
        manualSelection?: boolean,
        showing404?: boolean,
    ) {
        this.appState.moduleLoadingCount += 1

        const fullDomain = await fetchDomain(domain.id)

        this.appState.currentDomainJsonData = JSON.stringify(fullDomain ?? domain)
        setCurrentDomainSession(fullDomain)

        if (!showing404) {
            this.updateDomainInPlatformPath(fullDomain)
        }

        if (manualSelection && this.appState.showing404Count > 1) {
            this.routeWithinDomain('/dashboard')
        }

        this.appState.moduleLoadingCount -= 1
    }

    public get domainIdFromPath(): string | undefined {
        let id: any
        const idRequestRegx = /.*?\/domains\/([\d]+).*/i

        if (this.historyContainer) {
            const path = this.historyContainer.location.pathname
            if (idRequestRegx.test(path)) {
                id = path.replace(/.*?\/domains\/([\d]+).*/i, '$1')
            }
        }

        return id
    }

    public handleBackButtonNavigation(prevProps, currProps) {
        const realPrevRoute = prevProps.location.pathname
        const currHash = currProps.location.hash
        const hash = prevProps.location.hash

        // hash updates should not follow realPrevRoute by back button navigation due to
        // Tabs components using hash routing
        if (!!hash && hash !== currHash) {
            return
        }

        if (realPrevRoute === this.currentRoute) {
            this.historyContainer.goBack()
        }
    }

    @mobxAction.bound
    public async synchronizeDomainStates() {
        const coldStorageDomain = getCurrentDomainStorage()
        const sessionDomain = getCurrentDomainSession()
        const requestedDomainId = this.domainIdFromPath
        let invalidDomainRequest = false

        this.setAppLoading()

        let requestedDomain: DomainDto | undefined
        if (requestedDomainId) {
            const currentlyShowingId = sessionDomain?.id ?? coldStorageDomain?.id ?? undefined
            if (requestedDomainId !== String(currentlyShowingId)) {
                try {
                    requestedDomain = await fetchDomain(requestedDomainId)
                } catch {
                    invalidDomainRequest = true
                    this.set404State()
                }
            }
        }

        if (requestedDomain) {
            await this.setCurrentDomain(requestedDomain, false, invalidDomainRequest)
        } else if (sessionDomain) {
            await this.setCurrentDomain(sessionDomain, false, invalidDomainRequest)
        } else if (coldStorageDomain) {
            await this.setCurrentDomain(coldStorageDomain, false, invalidDomainRequest)
        }

        this.unsetAppLoading()
    }

    @mobxAction.bound
    public toggleSidebar() {
        this.appState.sidebar.collapsed = !this.appState.sidebar.collapsed
    }

    @mobxAction.bound
    public closeSidebar() {
        this.appState.sidebar.collapsed = true
    }

    @mobxAction.bound
    public openSidebar() {
        this.appState.sidebar.collapsed = false
    }

    @mobxAction.bound
    public setNotifListFilters(filter: 'source' | 'status', domainId: number, filters: string | string[]): void {
        filters = Array.isArray(filters) ? filters : filters.split(',')
        filters = filters.map((f) => f.toString().trim().toUpperCase()).filter((f) => !!f && f !== '')
        filters.sort()

        const currentFilters = this.getNotifListFilters(filter)
        currentFilters[domainId] = filters

        const notifListFilter = filter === 'source' ? 'notifListSourceFilters' : 'notifListStatusFilters'

        this.appState[notifListFilter] = JSON.stringify(currentFilters)
    }

    public getNotifListFilters(filter: 'source' | 'status', domainId?: number): any {
        const notifListFilter = filter === 'source' ? 'notifListSourceFilters' : 'notifListStatusFilters'

        let filters: any = this.appState[notifListFilter] ?? '{}'
        try {
            filters = JSON.parse(filters)
        } catch (_) {
            filters = {}
        }

        if (domainId !== undefined) {
            filters = filters[domainId] ?? []
        }

        return filters
    }

    // TODO: Cleanup set/get methods and how they are implemented
    @mobxAction.bound
    public setUserNotifChannelState(
        level: 'org' | 'domain',
        entity: (DomainChannelConfig & { id: number }) | Pick<AccountDto, 'flags' | 'id'>,
        change: DeliveryChannel[],
    ) {
        const allUserChannels = this.getAllUserNotifChannelState()
        const enabledChannelsForEntity =
            entity instanceof DomainDto
                ? getEnabledDeliveryChannels(entity, true)
                : getAccountEnabledDeliveryChannels(entity)

        allUserChannels[level][entity.id] = change.filter((ch) => enabledChannelsForEntity.includes(ch))

        this.appState.notifChannelSelection = JSON.stringify(allUserChannels)
    }

    /**
     * During switch from INotificationChannels {[DeliveryChannel]: boolean} -> DeliveryChannel[] value types, user preference were disconnected.
     * Now requires parsing of current value and changing to array. Should only occur during initial read
     *
     * Consider removing and moving to user data store preferences
     * @param entity
     * @param id
     */
    public getUserNotifChannelState(entity: 'org' | 'domain', id: number): DeliveryChannel[] {
        const currDomain = this.appState.currentDomain
        let raw: string = this.appState.notifChannelSelection ?? JSON.stringify(DEFAULT_USER_PREFERENCES)
        let userPreferences: IUserChannelPreferences
        let channels: DeliveryChannel[] = []

        let entityWithFlagConfig: (DomainChannelConfig | Pick<AccountDto, 'flags'>) & { id: number } = {
            id,
            flags: (entity === 'org' ? currDomain?.accountFlags : currDomain?.flags) ?? [],
            nativeApnsConfiguration: currDomain?.nativeApnsConfiguration,
            nativeFcmConfiguration: currDomain?.nativeFcmConfiguration,
        }

        try {
            userPreferences = JSON.parse(raw)
            channels = userPreferences[entity]?.[id]
            let legacyChannelConfigFound = false
            if (channels) {
                if (!Array.isArray(channels)) {
                    if (typeof channels === 'object') {
                        legacyChannelConfigFound = true
                        channels = (Object.keys(channels) as DeliveryChannel[]).filter((ch) => channels[ch] === true)
                    }
                }

                const enabledChannelsForEntity =
                    entity === 'domain'
                        ? getEnabledDeliveryChannels(entityWithFlagConfig as DomainChannelConfig, true)
                        : getAccountEnabledDeliveryChannels(entityWithFlagConfig as Pick<AccountDto, 'flags'>)

                // safe pref reset if channels are not part of entity enabled
                if (channels.some((ch) => !enabledChannelsForEntity.includes(ch))) {
                    channels = enabledChannelsForEntity
                    this.setUserNotifChannelState(entity, entityWithFlagConfig, enabledChannelsForEntity)
                } else if (legacyChannelConfigFound) {
                    this.setUserNotifChannelState(entity, entityWithFlagConfig, channels)
                }
            }
        } catch (_) {}

        return channels
    }

    @mobxAction.bound
    public setAppMessageListFilters(filter: 'channel' | 'status', domainId: number, filters: string | string[]): void {
        filters = Array.isArray(filters) ? filters : filters.split(',')
        filters = filters.map((f) => f.toString().trim().toUpperCase()).filter((f) => !!f && f !== '')
        filters.sort()

        const currentFilters = this.getAppMessageListFilters(filter)
        currentFilters[domainId] = filters

        const appMessageListFilter =
            filter === 'channel' ? 'appMessageListChannelFilters' : 'appMessageListStatusFilters'

        this.appState[appMessageListFilter] = JSON.stringify(currentFilters)
    }

    public getAppMessageListFilters(filter: 'channel' | 'status', domainId?: number): any {
        const appMessageListFilter =
            filter === 'channel' ? 'appMessageListChannelFilters' : 'appMessageListStatusFilters'

        let filters: any = this.appState[appMessageListFilter] ?? '{}'

        try {
            filters = JSON.parse(filters)
        } catch (_) {
            filters = {}
        }

        if (domainId !== undefined) {
            filters = filters[domainId] ?? []
        }

        return filters
    }

    public startFetchingPlatformMessages(): void {
        if (!this.platformMessageAuthRefreshCleaner) {
            const [cleaner] = addVisibilityAwareIntervalRunner(
                'platform-messages',
                async () => {
                    await this.updatePlatformMessages()
                },
                60000,
            )

            this.platformMessageAuthRefreshCleaner = cleaner
        }
    }

    public stopFetchingPlatformMessages(): void {
        this.platformMessageAuthRefreshCleaner?.()
        this.platformMessageAuthRefreshCleaner = undefined
    }

    public getCurrentPlatformMessages(): PlatformMessageDto[] {
        const user = this.appState.currentUser!
        const seenMsgs = this.getUserPlatformMessagesFromStorage()
        let platformMsgs = this.appState.platformMessages || []

        if (user) {
            // Filter already seen messages out
            platformMsgs = platformMsgs.filter((msg) => !arrayContains(seenMsgs, `u${user.id}::${msg.id}`))
            // Filter remaining by domain visibility
            platformMsgs = platformMsgs.filter(
                (msg) => !msg.domainIds || arrayContains(msg.domainIds, this.appState.currentDomain!.id),
            )
        }

        return platformMsgs
    }

    public setPlatformMessageAsSeen(msgId: number): void {
        const user = this.appState.currentUser!
        const seenMsgs = this.getUserPlatformMessagesFromStorage()
        const seenMsgKey = `u${user.id}::${msgId}`

        this.appState.userPlatformMessages = JSON.stringify([
            ...seenMsgs.filter((key) => key !== seenMsgKey),
            seenMsgKey,
        ])
    }

    public async fetchPlatformMessages(options?: any): Promise<PlatformMessageDto[]> {
        options = options || {}
        if (options.pagination === void 0) options.pagination = false
        options = querystring.stringify(options || {})

        let messages: PlatformMessageDto[] = []
        try {
            const req = await axios.get(`${aqe.defaults.publicApiDomain}/system/messages?${options}`)
            messages = req.data.data
        } catch {}

        return messages
    }

    public async getEventLogs(opts: any = {}, showLoadingScreen: boolean = false): Promise<any> {
        if (showLoadingScreen) this.setModuleLoading()
        let res: any = { data: [], total: 0, count: 0 }

        let logs: any
        try {
            const options = querystring.stringify(opts || {})
            const serviceURL = `${aqe.defaults.publicApiDomain}/system/event-logs${options}`

            res = await axios.get(serviceURL)

            logs = res.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) this.unsetModuleLoading()

        return logs
    }

    public customizeTabTitle = (): void => {
        let page: string = 'Pushly'

        const titleEl = document.querySelector('h2')
        if (titleEl) {
            page = titleEl!.textContent!
        }

        if (this.appState.inDomainContext) {
            const currentDomain = this.appState.currentDomain
            const domainDisplayName = currentDomain?.displayName

            if (domainDisplayName) {
                document.title = `${domainDisplayName} - ${page}`
            }
        }
        if (this.appState.inOrgContext) {
            const accountName = this.appState.currentDomain?.accountName

            if (accountName) {
                document.title = `${accountName} - ${page}`
            }
        }

        const hash = window.location.hash
        if (hash) {
            const subpage = titleCase(hash.substring(1))
            if (page !== subpage) {
                document.title = `${document.title} - ${subpage}`
            }
        }
    }

    protected getAllUserNotifChannelState() {
        let raw: string = this.appState.notifChannelSelection ?? JSON.stringify(DEFAULT_USER_PREFERENCES)
        let userChannels
        try {
            userChannels = JSON.parse(raw)
        } catch (_) {}

        return userChannels ?? DEFAULT_USER_PREFERENCES
    }

    protected getUserPlatformMessagesFromStorage(): any[] {
        const rawSeenMsgs = this.appState.userPlatformMessages || '[]'
        let seenMsgs: any[] = []

        try {
            seenMsgs = JSON.parse(rawSeenMsgs) as any[]
        } catch (error) {}

        return seenMsgs
    }

    protected async updatePlatformMessages(): Promise<void> {
        if (!this.fetchingPlatformMessages) {
            this.appState.platformMessages = await this.fetchPlatformMessages({ active: true })
        }
    }

    protected updateDomainInPlatformPath(domain: DomainDto): void {
        const currentDomainPath = this.currentRoute
        const newDomainPath = currentDomainPath.replace(/\/?domains\/[\d]+\/?/i, `/domains/${domain.id}/`)
        if (newDomainPath !== currentDomainPath) {
            this.route(newDomainPath)
        }
    }
}
