import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Radio, Card, Select, Tooltip } from 'antd'
import { arrayContains, pause, simpleFormErrorNotification } from '../../../_utils/utils'
import { Well } from '../../../components/well/well'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { DomainDto } from '../../../dtos/domain'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services'
import { PromptStyle } from './prompt-editor'
import { SystemPromptDataForm } from '../system-prompt-data-form/system-prompt-data-form'
import { CustomPromptDataForm } from '../custom-prompt-data-form/custom-prompt-data-form'
import { SlidePromptDataForm } from '../slide-prompt-data-form/slide-prompt-data-form'
import { BellPromptDataForm } from '../bell-prompt-data-form/bell-prompt-data-form'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { RadioChangeEvent } from 'antd/lib/radio'
import { PromptDto } from '../../../dtos/prompt'
import { PromptSystemConfigDto } from '../../../dtos/prompt-system-config-dto'
import { PromptSlideConfigDto } from '../../../dtos/prompt-slide-config-dto'
import { PromptBellConfigDto } from '../../../dtos/prompt-bell-config-dto'
import { InfoCircleOutlined } from '@ant-design/icons'

let UPDATE_TREATMENT_DEBOUNCE: any

export enum VisitorCondition {
    EVERYONE = 'EVERYONE',
    TIME_ON_PAGE = 'TIME_ON_PAGE',
    SESSION_PAGE_VIEWS = 'SESSION_PAGE_VIEWS',
    SESSION_TIME_ON_SITE = 'SESSION_TIME_ON_SITE',
}

export interface IPromptTreatment extends PromptDto {}

function treatmentIsCustomPrompt(treatment: IPromptTreatment): boolean {
    return treatment.style === PromptStyle.PUBLISHER_CUSTOM
}
function treatmentIsSlidePrompt(
    treatment: IPromptTreatment,
): treatment is PromptDto & { config: PromptSlideConfigDto } {
    return treatment.style === PromptStyle.SLIDE
}
function treatmentIsBellPrompt(treatment: IPromptTreatment): treatment is PromptDto & { config: PromptBellConfigDto } {
    return treatment.style === PromptStyle.BELL
}
function treatmentIsSystemPrompt(
    treatment: IPromptTreatment,
): treatment is PromptDto & { config: PromptSystemConfigDto } {
    return treatment.style === PromptStyle.SYSTEM
}

interface IProps extends FormComponentProps {
    treatment: IPromptTreatment
    onChange: (value: IPromptTreatment) => any
    abTestEnabled?: boolean
    validatorRef?: (fn: Function) => any

    errors?: ReadonlyArray<{ field: string; message: string }>
}

interface IState {
    domain: DomainDto
    value: IPromptTreatment
    selectedStyle: PromptStyle | string
    visitorCondition: VisitorCondition

    slidePromptImageUpload?: any
}

@observer
class PromptTreatmentEditorForm extends BetterFormComponent<IProps, IState> {
    private readonly appState: AppState
    private readonly appService: AppService
    private readonly domainService: DomainService

    private containerRef: any
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        const initialValue: IPromptTreatment = props.treatment || {}

        this.state = {
            domain: this.appState.currentDomain!,
            value: initialValue,
            selectedStyle: initialValue.style || PromptStyle.SYSTEM,
            visitorCondition: VisitorCondition.EVERYONE,
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()

        if (!!this.props.validatorRef) {
            this.props.validatorRef(this.handleValidation)
        }
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()
        this.disposeObservers.forEach((f: any) => f())
    }

    public componentDidUpdate(): void {
        if (this.state.selectedStyle !== this.props.treatment.style) {
            this.setState({ selectedStyle: this.props.treatment.style })
        }
    }

    public render(): React.ReactNode {
        return (
            <div ref={(element) => (this.containerRef = element)} className="treatment-form">
                {this.props.abTestEnabled && this.renderTreatmentDetailsEditor()}
                {this.renderStyleSelector()}
                {this.renderThemeEditor()}
                {!treatmentIsCustomPrompt(this.props.treatment) && this.renderBehaviorEditor()}
                {this.renderVisitorConditionEditor()}
            </div>
        )
    }

    private renderTreatmentDetailsEditor(): React.ReactNode {
        const { treatment } = this.props

        return (
            <Well showHeader={false} showFooter={false} ghost={true}>
                <div className="prompt-test-setup-well">
                    <Form.Item label="Variant Name">
                        {this.getFieldDecorator('treatmentName', {
                            initialValue: treatment.name,
                            rules: [
                                {
                                    required: true,
                                    message: 'Variant name is required',
                                },
                                {
                                    max: 45,
                                    message: 'Variant name cannot exceed 45 characters',
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder="Prompt Variant Name"
                                maxLength={45}
                                onChange={this.updateTreatment}
                            />,
                        )}
                    </Form.Item>

                    <div className="display-weight">
                        <Form.Item
                            label={
                                <div>
                                    <Tooltip
                                        title={
                                            'Display weight determines the percent of site visitors that will see the currently selected prompt test. The display weight must add up to 100% across all tests.'
                                        }
                                    >
                                        Display Weight
                                        <span className="info-icon">
                                            <InfoCircleOutlined />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        >
                            {this.getFieldDecorator('treatmentWeight', {
                                initialValue: treatment.weight || 0,
                            })(
                                <Input
                                    size="large"
                                    placeholder="0"
                                    addonAfter="%"
                                    type="number"
                                    max={100}
                                    min={0}
                                    maxLength={3}
                                    onChange={this.updateTreatment}
                                />,
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Well>
        )
    }

    private renderStyleSelector(): React.ReactNode {
        const { domain } = this.state

        return (
            <Well title="Style" showFooter={false} ghost={true}>
                {this.getFieldDecorator('style', {
                    initialValue: this.props.treatment.style,
                })(
                    <div className="prompt-style-options">
                        {this.renderPromptOption(PromptStyle.SYSTEM, 'System Prompt')}
                        {this.renderPromptOption(PromptStyle.SLIDE, 'Slide Prompt')}
                        {this.renderPromptOption(PromptStyle.BELL, 'Bell Prompt')}
                        {this.renderPromptOption(PromptStyle.PUBLISHER_CUSTOM, 'Custom Prompt')}
                    </div>,
                )}
            </Well>
        )
    }

    private renderThemeEditor(): React.ReactNode {
        let themeEditor: any = ''

        if (treatmentIsSystemPrompt(this.props.treatment)) {
            themeEditor = (
                <SystemPromptDataForm
                    {...this.props}
                    key={'system-data-form'}
                    prompt={this.props.treatment}
                    onFieldChange={this.updateTreatment}
                />
            )
        }

        if (treatmentIsCustomPrompt(this.props.treatment)) {
            themeEditor = (
                <CustomPromptDataForm {...this.props} key={'custom-data-form'} prompt={this.props.treatment} />
            )
        }

        if (treatmentIsSlidePrompt(this.props.treatment)) {
            themeEditor = (
                <SlidePromptDataForm
                    {...this.props}
                    key={'slide-data-form'}
                    prompt={this.props.treatment}
                    onFieldChange={this.updateTreatment}
                    onUploadChange={this.handleSlideImageChange}
                />
            )
        }

        if (treatmentIsBellPrompt(this.props.treatment)) {
            themeEditor = (
                <BellPromptDataForm
                    {...this.props}
                    key={'bell-data-form'}
                    prompt={this.props.treatment}
                    onFieldChange={this.updateTreatment}
                />
            )
        }

        return themeEditor
    }

    private renderBehaviorEditor(): React.ReactNode {
        const { getFieldDecorator } = this.props.form
        const { treatment } = this.props

        let defaultCookieDelay = 7
        if (treatment && this.getPromptBehaviors().cookieLengthSeconds) {
            defaultCookieDelay = this.getPromptBehaviors().cookieLengthSeconds / 24 / 60 / 60
        }

        const shouldShowCookieLength =
            this.state.selectedStyle === PromptStyle.SLIDE || this.state.selectedStyle === PromptStyle.SYSTEM

        return (
            <Well title="Behaviors" showFooter={false} ghost={true}>
                <div className="prompt-behavior-well">
                    {shouldShowCookieLength && (
                        <Form.Item className="cookie-len" label="Re-show Dismissed Prompt After">
                            {getFieldDecorator('cookieLength', {
                                initialValue: defaultCookieDelay,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Prompt dismiss time is required',
                                    },
                                    {
                                        pattern: /^[\d]+$/i,
                                        message: 'Prompt dismiss time must be a number',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            value >= 1 ? callback() : callback(new Error(rule.message))
                                        },
                                        message: 'Prompt dismiss time cannot be 0',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            value <= 3650 ? callback() : callback(new Error(rule.message))
                                        },
                                        message: 'Prompt dismiss time cannot exceed 3,650',
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder="7"
                                    type="number"
                                    addonAfter="day(s)"
                                    onChange={this.debounceUpdateTreatment}
                                />,
                            )}
                        </Form.Item>
                    )}

                    {treatmentIsBellPrompt(this.props.treatment) && (
                        <div>
                            <Form.Item label="Display Behavior">
                                {getFieldDecorator('displayBehavior', {
                                    initialValue: this.getPromptBehaviors().displayBehavior || 'ALWAYS',
                                })(
                                    <Select size="large" onChange={this.updateTreatment}>
                                        <Select.Option value="ALWAYS">Always show</Select.Option>
                                        <Select.Option value="PRE_SUBSCRIPTION">
                                            Before user is subscribed
                                        </Select.Option>
                                        <Select.Option value="POST_SUBSCRIPTION">
                                            After user is subscribed
                                        </Select.Option>
                                    </Select>,
                                )}
                            </Form.Item>
                        </div>
                    )}
                </div>
            </Well>
        )
    }

    private renderVisitorConditionEditor(): React.ReactNode {
        const { getFieldDecorator } = this.props.form

        let defaultCondition = this.state.visitorCondition || VisitorCondition.EVERYONE
        const currentConditions = this.getPromptVisitorConditions()

        if (!!currentConditions) {
            if (!!currentConditions.timeOnPageSeconds) {
                defaultCondition = VisitorCondition.TIME_ON_PAGE
            } else if (!!currentConditions.sessionPageViews) {
                defaultCondition = VisitorCondition.SESSION_PAGE_VIEWS
            } else if (!!currentConditions.sessionTimeOnSiteSeconds) {
                defaultCondition = VisitorCondition.SESSION_TIME_ON_SITE
            }
        }

        let conditionValueEditor: any
        switch (defaultCondition) {
            case VisitorCondition.TIME_ON_PAGE:
                conditionValueEditor = this.renderTimeOnPageCondition()
                break
            case VisitorCondition.SESSION_PAGE_VIEWS:
                conditionValueEditor = this.renderSPageViewsCondition()
                break
            case VisitorCondition.SESSION_TIME_ON_SITE:
                conditionValueEditor = this.renderSTimeOnSiteCondition()
                break
        }

        return (
            <Well title="Visitor Conditions" showFooter={false} ghost={true}>
                <p>
                    By default this prompt will be shown to the visitor immediately. You may choose to delay the prompt
                    display by enabling one of the below conditions.
                </p>

                <Form.Item className="visitor-condition-type" label="Users will receive this prompt after::">
                    {getFieldDecorator('visitorCondition', {
                        initialValue: defaultCondition,
                    })(
                        <Radio.Group size="small" onChange={this.setVisitorCondition}>
                            <Radio.Button value={VisitorCondition.EVERYONE}>Page Load</Radio.Button>
                            <Radio.Button value={VisitorCondition.TIME_ON_PAGE}>Time on Page</Radio.Button>
                            <Radio.Button value={VisitorCondition.SESSION_PAGE_VIEWS}># of Page Views</Radio.Button>
                            <Radio.Button value={VisitorCondition.SESSION_TIME_ON_SITE}>Time on Site</Radio.Button>
                        </Radio.Group>,
                    )}
                    {conditionValueEditor}
                </Form.Item>
            </Well>
        )
    }

    private renderTimeOnPageCondition(): React.ReactNode {
        return this.getFieldDecorator('timeOnPageSeconds', {
            initialValue: this.getPromptVisitorConditions().timeOnPageSeconds || 1,
            rules: [
                {
                    required: true,
                    message: 'Time on page is required',
                },
                {
                    pattern: /^[\d]+$/i,
                    message: 'Time on page must be in whole seconds',
                },
                {
                    validator: (rule, value, callback) => {
                        value >= 1 ? callback() : callback(new Error(rule.message))
                    },
                    message: 'Time on page must be greater than 0 seconds',
                },
            ],
        })(
            <Input
                size="large"
                placeholder="0"
                addonAfter="seconds spent on page"
                className="vc-time-on-page"
                type="number"
                min={0}
                onChange={this.updateTreatment}
            />,
        )
    }

    private renderSPageViewsCondition(): React.ReactNode {
        return this.getFieldDecorator('pageViews', {
            initialValue: this.getPromptVisitorConditions().sessionPageViews || 2,
            rules: [
                {
                    required: true,
                    message: 'Page view count is required',
                },
                {
                    pattern: /^[\d]+$/i,
                    message: 'Page views must be a whole number',
                },
                {
                    validator: (rule, value, callback) => {
                        value >= 2 ? callback() : callback(new Error(rule.message))
                    },
                    message: 'Page views must be greater than 1 page',
                },
            ],
        })(
            <Input
                size="large"
                placeholder="0"
                addonAfter="pages viewed"
                className="vc-pages-viewed"
                type="number"
                min={0}
                onChange={this.updateTreatment}
            />,
        )
    }

    private renderSTimeOnSiteCondition(): React.ReactNode {
        return this.getFieldDecorator('timeOnSiteSeconds', {
            initialValue: this.getPromptVisitorConditions().sessionTimeOnSiteSeconds || 1,
            rules: [
                {
                    required: true,
                    message: 'Time on site is required',
                },
                {
                    pattern: /^[\d]+$/i,
                    message: 'Time on site must be in whole seconds',
                },
                {
                    validator: (rule, value, callback) => {
                        value >= 1 ? callback() : callback(new Error(rule.message))
                    },
                    message: 'Time on site must be greater than 0 seconds',
                },
            ],
        })(
            <Input
                size="large"
                placeholder="0"
                addonAfter="seconds spent on site"
                className="vc-time-on-site"
                type="number"
                min={0}
                onChange={this.updateTreatment}
            />,
        )
    }

    private renderPromptOption(style: PromptStyle, title: string): React.ReactNode {
        const classNames: string[] = [`prompt-style-${style.toLowerCase()}`]

        if (style === this.state.selectedStyle) {
            classNames.push('selected')
        }

        return (
            <div onClick={() => this.setSelectedStyleState(style)}>
                <Card className={classNames.join(' ')}>
                    <Card.Meta title={title} />
                </Card>
            </div>
        )
    }

    private async setDomainState(): Promise<void> {
        const state: any = {
            domain: this.appState.currentDomain,
        }

        this.setState(state)
        this.setDomainPromptStyles()
    }

    private async setDomainPromptStyles(style?: PromptStyle | string) {
        style = style ?? this.state.selectedStyle
        if (style === PromptStyle.SLIDE && this.state.domain.defaultIconUrl) {
            await this.setState(({ domain }) => ({
                slidePromptImageUpload: { url: domain.defaultIconUrl },
            }))
        }
    }

    @autobind
    private async setSelectedStyleState(selectedStyle: PromptStyle): Promise<void> {
        this.props.form.setFieldsValue({ style: selectedStyle })
        await this.setDomainPromptStyles(selectedStyle)
        this.updateTreatment()
    }

    @autobind
    private async setVisitorCondition(ev: RadioChangeEvent): Promise<void> {
        await this.setState({ visitorCondition: ev.target.value })
        this.updateTreatment()
    }

    private getPromptBehaviors(): any {
        const { treatment } = this.props
        return treatment.config?.behavior ?? {}
    }

    private getPromptVisitorConditions(): any {
        const { treatment } = this.props
        return treatment.conditions?.visitor ?? {}
    }

    private async getCurrentFieldValues(): Promise<any> {
        await pause(5) // handle getFieldValue timing issue
        return this.props.form.getFieldsValue()
    }

    @autobind
    private async handleSlideImageChange(slidePromptImageUpload: any) {
        await this.setState({ slidePromptImageUpload })
        this.updateTreatment()
    }

    @autobind
    private async handleValidation() {
        return this.validateFields()
    }

    @autobind
    private async clearTreatment(): Promise<void> {
        const value: IPromptTreatment = {
            id: (this.props.treatment || {}).id,
            style: this.state.selectedStyle,
        } as any

        this.setState(() => ({ value }))
        this.props.onChange(value)
    }

    @autobind
    private async debounceUpdateTreatment(): Promise<void> {
        clearTimeout(UPDATE_TREATMENT_DEBOUNCE)
        UPDATE_TREATMENT_DEBOUNCE = setTimeout(() => this.updateTreatment(), 320)
    }

    @autobind
    private async updateTreatment(ev?: any): Promise<void> {
        try {
            if (!!ev && 'preventDefault' in ev) {
                ev.preventDefault()
                ev.stopPropagation()

                if ('nativeEvent' in ev) {
                    ev.nativeEvent.preventDefault()
                    ev.nativeEvent.stopPropagation()
                }
            }
        } catch {}

        const value: IPromptTreatment = {
            id: (this.props.treatment || {}).id,
            style: this.state.selectedStyle,
        } as any

        try {
            const fieldValues = await this.getCurrentFieldValues()
            if (fieldValues.style) {
                value.style = fieldValues.style
            }

            value.name = fieldValues.treatmentName
            value.weight = fieldValues.treatmentWeight

            try {
                value.weight = parseInt(String(value.weight), 10)
            } catch {}

            if (value.style === PromptStyle.PUBLISHER_CUSTOM) {
                value.config = {}
            }
            if (value.style === PromptStyle.SYSTEM) {
                value.config = this.buildSystemPromptConfig(fieldValues)
            }
            if (value.style === PromptStyle.SLIDE) {
                value.config = this.buildSlidePromptConfig(fieldValues)
            }
            if (value.style === PromptStyle.BELL) {
                value.config = this.buildBellPromptConfig(fieldValues)
            }

            if (fieldValues.visitorCondition !== VisitorCondition.EVERYONE) {
                value.conditions = value.conditions || {}
                value.conditions.visitor = {}

                switch (fieldValues.visitorCondition) {
                    case VisitorCondition.TIME_ON_PAGE:
                        value.conditions.visitor.timeOnPageSeconds = parseInt(fieldValues.timeOnPageSeconds, 10)
                        break
                    case VisitorCondition.SESSION_PAGE_VIEWS:
                        value.conditions.visitor.sessionPageViews = parseInt(fieldValues.pageViews, 10)
                        break
                    case VisitorCondition.SESSION_TIME_ON_SITE:
                        value.conditions.visitor.sessionTimeOnSiteSeconds = parseInt(fieldValues.timeOnSiteSeconds, 10)
                        break
                }
            } else {
                value.conditions = null
            }

            const parsedValueDto = PromptDto.parse(value)
            this.props.onChange(parsedValueDto)
            await this.setState(() => ({ value: parsedValueDto }))
        } catch (error) {
            simpleFormErrorNotification(error)
        }
    }

    private buildSystemPromptConfig(values: any): PromptSystemConfigDto {
        const config = new PromptSystemConfigDto()
        config.behavior = {}

        if (values.cookieLength) {
            config.behavior.cookieLengthSeconds = values.cookieLength * 60 * 60 * 24
        }

        return config
    }

    private buildSlidePromptConfig(values: any): PromptSlideConfigDto {
        const config = new PromptSlideConfigDto()
        config.behavior = {}

        const defaultAccentColor = '#555'
        const defaultLayout = 'normal'
        const defaultDesktopPosition = 'top'
        const defaultMobilePosition = 'bottom'
        const defaultBackgroundColor = '#FFFFFF'
        const defaultTitleColor = defaultAccentColor
        const defaultSubTitleColor = defaultAccentColor
        const defaultAllowText = 'Allow'
        const defaultAllowTextColor = defaultBackgroundColor
        const defaultAllowButtonColor = defaultAccentColor
        const defaultDismissText = 'Dismiss'
        const defaultDismissTextColor = defaultAccentColor
        const defaultDismissButtonColor = defaultBackgroundColor

        const desktopPosition = values.desktopPosition_slide || values.desktopPosition_bell || defaultDesktopPosition
        const mobilePosition = values.mobilePosition_slide || values.mobilePosition_bell || defaultMobilePosition

        config.theme = {
            layout: values.layout || defaultLayout,
            position: {
                desktop: desktopPosition,
                mobile: mobilePosition,
            },
            backgroundColor: values.backgroundColor || defaultBackgroundColor,
            title: {
                text: values.title || 'We would like to send you notifications',
                textColor: values.titleColor || defaultTitleColor,
            },
            subtitle: {
                text: values.subtitle || 'Notifications can be turned off anytime from browser settings',
                textColor: values.subtitleColor || defaultSubTitleColor,
            },
            allowButton: {
                text: values.allowButtonText || defaultAllowText,
                textColor: values.allowButtonTextColor || defaultAllowTextColor,
                backgroundColor: values.allowButtonColor || defaultAllowButtonColor,
            },
            dismissButton: {
                text: values.dismissButtonText || defaultDismissText,
                textColor: values.dismissButtonTextColor || defaultDismissTextColor,
                backgroundColor: values.dismissButtonColor || defaultDismissButtonColor,
            },
        }

        if (values.cookieLength) {
            config.behavior.cookieLengthSeconds = values.cookieLength * 60 * 60 * 24
        }

        const blockedPositions = ['bottom-left', 'bottom-right', 'top-left', 'top-right']
        if (arrayContains(blockedPositions, String(desktopPosition))) {
            config.theme.position.desktop = defaultDesktopPosition
        }
        if (arrayContains(blockedPositions, String(mobilePosition))) {
            config.theme.position.mobile = defaultMobilePosition
        }

        if (this.state.slidePromptImageUpload) {
            config.theme.image = this.state.slidePromptImageUpload.url
        }

        return config
    }

    private buildBellPromptConfig(values: any): PromptBellConfigDto {
        const config = new PromptBellConfigDto()
        config.behavior = {
            displayBehavior: 'ALWAYS',
        }

        const defaultPosition = 'bottom-left'
        const defaultSize = 45
        const defaultPrimaryColor = '#0077A0'
        const defaultAccentColor = '#FFFFFF'

        const desktopPosition = values.desktopPosition_bell || values.desktopPosition_slide || defaultPosition
        const mobilePosition = values.mobilePosition_bell || values.mobilePosition_slide || defaultPosition

        config.theme = {
            position: {
                desktop: desktopPosition,
                mobile: mobilePosition,
            },
            tooltip: {},
            size: parseInt(values.size, 10) || defaultSize,
            mobileSize: parseInt(values.mobileSize, 10) || defaultSize,
            primaryColor: values.primaryColor || defaultPrimaryColor,
            accentColor: values.accentColor || defaultAccentColor,
        }

        if (values.displayBehavior) {
            config.behavior.displayBehavior = values.displayBehavior
        }

        const blockedPositions = ['top', 'bottom']
        if (arrayContains(blockedPositions, String(desktopPosition))) {
            config.theme.position.desktop = defaultPosition
        }
        if (arrayContains(blockedPositions, String(mobilePosition))) {
            config.theme.position.mobile = defaultPosition
        }

        if (values.unsubscribedTooltip) config.theme.tooltip!.unsubscribed = values.unsubscribedTooltip
        if (values.subscribedTooltip) config.theme.tooltip!.subscribed = values.subscribedTooltip

        return config
    }
}

export const PromptTreatmentEditor = Form.create<IProps>()(PromptTreatmentEditorForm)
