import React, { useCallback, useEffect } from 'react'
import './data-view.scss'
import { DataViewProps, LevelResource } from './types'
import classnames from 'classnames'
import { Well } from '@pushly/aqe/lib/components'
import { DataViewHeader } from './data-view-header'
import { useDataViewContext } from './context'
import { DataViewResourceLoader } from './data-view-resource-loader'
import { useService } from '@pushly/aqe/lib/hooks'
import { AccountService, DomainService } from '../../services'
import { addVisibilityAwareIntervalRunner } from '../../_utils/visibility-api'
import { DataViewTable } from './data-view-table'
import { AppState } from '../../stores/app'

export const DataView = <T extends {} = any>(props: DataViewProps<T>) => {
    const [state, dispatch] = useDataViewContext<T>()
    const { instanceId, level, levelResourceId } = state

    const appState = useService(AppState)
    const orgService = useService(AccountService)
    const domainService = useService(DomainService)

    /**
     * <b>Level (Org|Domain) Loader</b>
     *
     * If props.levelResourceLoader override is not provided
     * the loader defaults to using the level associated service
     * fetchById method.
     *
     * When level is domain the default loader will first attempt
     * to locate a previously fetched Domain object before making
     * a network call.
     */
    const levelResourceLoader = useCallback(async () => {
        let data: LevelResource

        if (props.levelResourceLoader) {
            data = await props.levelResourceLoader()
        } else if (level === 'org') {
            data = await orgService.fetchById(levelResourceId)
        } else {
            const domainMatch = appState.currentUserDomains?.find((d) => {
                return d.id === levelResourceId
            })

            // Attempt to use already fetched domain before making network call
            if (domainMatch) {
                data = (await domainService.fetchById(domainMatch.id)).data
            } else {
                data = (await domainService.fetchById(levelResourceId)).data
            }
        }

        return data
    }, [level, levelResourceId, props.levelResourceLoader])

    // ----- Auto-refresh state control -----
    useEffect(() => {
        const [cleaner, updateTS] = addVisibilityAwareIntervalRunner(
            `data-view-list-${instanceId}`,
            async () => {
                if (state.refreshEnabled) {
                    dispatch({ type: 'SET_AUTO_REFRESH_REQUESTED' })
                    props.onAutoRefresh?.()
                }
            },
            state.refreshTimeout,
            true,
        )

        dispatch({ type: 'SET_REFRESH_TIMESTAMP_UPDATE_HANDLER', data: updateTS })

        return cleaner
    }, [level, levelResourceId, state.refreshEnabled, state.refreshTimeout, !!props.onAutoRefresh])
    // -----

    return (
        <React.Fragment key={state.instanceId}>
            <DataViewResourceLoader
                level={level}
                type="level"
                loader={levelResourceLoader}
                onLoaded={props.onLevelResourceLoaded}
            />
            <DataViewResourceLoader
                level={level}
                type="dataSource"
                loader={props.dataSourceLoader}
                onLoaded={props.onDataSourceLoaded}
            />

            <Well
                className={classnames([
                    'data-view',
                    'table-well',
                    'nested',
                    state.id.toLowerCase(),
                    state.instanceId,
                    props.className,
                ])}
                header={
                    <DataViewHeader
                        title={props.title}
                        filters={props.filters}
                        filterSize={props.filterSize}
                        filtersAddonBefore={props.filtersAddonBefore}
                        filtersAddonAfter={props.filtersAddonAfter}
                        hideRefresh={props.hideRefresh}
                        hideAutoRefreshOptions={props.hideAutoRefreshOptions}
                    />
                }
                hideFooter={true}
            >
                <DataViewTable {...props.table}>{props.children}</DataViewTable>
            </Well>
        </React.Fragment>
    )
}
