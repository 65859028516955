import { IDomainFrequencyCaps, IDomainGlobalPromptSettings, IDomainUtmParams } from '../../../dtos/domain'
import { IWebApnsSendIntegration } from '../../../interfaces/send-integrations'

export class DomainCreateRequestDto {
    public static parse(fields: DomainCreateRequestDto): DomainCreateRequestDto {
        const dto = new DomainCreateRequestDto()

        type AllowedType = keyof DomainCreateRequestDto
        const fieldsToAssign: AllowedType[] = [
            'name',
            'accountId',
            'displayName',
            'timezone',
            'defaultIconUrl',
            'defaultBadgeUrl',
            'defaultNotificationTtlSeconds',
            'senderId',
            'fcmServerKey',
            'fcmCredentialsJson',
            'frequencyCaps',
            'utmParams',
            'globalPromptSettings',
            'apnsConfiguration',
            'whitelistDomains',
            'flags',
        ]

        fieldsToAssign.forEach((key) => {
            if (fields[key]) {
                dto[key] = fields[key]
            }
        })

        return dto
    }

    public name?: string
    public accountId?: number
    public displayName?: string
    public timezone?: string
    public defaultIconUrl?: string
    public defaultBadgeUrl?: string
    public defaultNotificationTtlSeconds?: number
    public senderId?: string
    public fcmServerKey?: string
    public fcmCredentialsJson?: any
    public frequencyCaps?: IDomainFrequencyCaps | null
    public utmParams?: IDomainUtmParams | null
    public globalPromptSettings?: IDomainGlobalPromptSettings
    public apnsConfiguration?: IWebApnsSendIntegration | null
    public whitelistDomains?: string[] | null
    public flags?: string[]
}
