import { Container, Singleton } from 'typescript-ioc/es5'
import * as querystring from 'query-string'
import { convertCase, fetchDomain, simpleNotification } from '../_utils/utils'
import { AppService } from './app'
import { DomainDto, EcommItemGroup } from '../dtos/domain'
import { DomainUpdateRequestDto } from '../features/domains'
import { SegmentDto } from '../dtos/segment'
import { DomainKeyword, DomainPageTag } from '../dtos/domain'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { AppState } from '../stores/app'
import IApiCallOptions from '../interfaces/api-call-options'
import aqe from '@pushly/aqe'
import fileDownload from 'js-file-download'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { Domain } from '@pushly/models/lib/structs/domain'
import { hydrateDomainModel } from '../_utils/models-hydration'

export type EstimateReturnType = 'count' | 'count_by_channel'
export type RequiredAudienceType = 'NOTIFICATION' | 'APP_MESSAGE'
export type ReachByChannelResponse = {
    native_ios: number
    web: number
    native_android: number
}

@Singleton
export class DomainService {
    public appService: AppService
    public appState: AppState

    public constructor() {
        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
    }

    public async fetchById(domainId: number, options?: any): Promise<IServiceApiResponse<DomainDto>> {
        let ok = false
        let domain: DomainDto

        try {
            domain = await fetchDomain(domainId)
            ok = true
        } catch (err) {}

        return { ok, data: domain! }
    }

    public async fetchAllForUserId(userId: number, cancellationKey?: string): Promise<DomainDto[]> {
        const request = await axiosFetch(
            'get',
            {
                url: `${aqe.defaults.publicApiDomain}/v3/users/${userId}/domains`,
            },
            cancellationKey,
        )
        const domains: any = request.data.data
        return domains.map((domain: any) => DomainDto.fromApiResponse(domain))
    }

    public async fetchByAccountId(
        accountId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<DomainDto[]>> {
        let ok = false
        let dtos: DomainDto[] = []
        let meta: any

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/accounts/${accountId}/domains?${options}`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            ok = true
            dtos = req.data.data.map(DomainDto.fromApiResponse)
            meta = req.data.meta
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: dtos, meta }
    }

    /**
     * Legacy domain update using DTO
     *
     * @param domainId
     * @param domainOptions
     * @param cancellationKey
     */
    public async updateDomainById(
        domainId: number,
        domainOptions: any,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<DomainDto>> {
        let ok = false
        let domain: DomainDto
        const requestDto = DomainUpdateRequestDto.parse(domainOptions)

        this.appService.setModuleLoading()

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}`,
                    data: requestDto,
                },
                cancellationKey,
            )

            ok = true
            domain = DomainDto.fromApiResponse(req.data.data)

            this.appService.updateDomain(domain)
            await this.appService.setCurrentDomain(domain)

            simpleNotification('success', `${domain.displayName} was successfully updated`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return { ok, data: domain! }
    }

    /**
     * True PATCH request for updating partial Domain entity. Uses new Domain Model.
     *
     * @param domainId
     * @param domainPartial
     * @param cancellationKey
     */
    public async patchDomainById(
        domainId: number,
        domainPartial: Partial<Domain>,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<Domain>> {
        let ok = false
        let domain: Domain
        this.appService.setModuleLoading()

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}`,
                    data: domainPartial,
                },
                cancellationKey,
            )

            ok = true
            domain = hydrateDomainModel(req.data.data)

            // V1 using DomainDto prior to updating app wide Domain Model types
            const legacyDto = DomainDto.fromApiResponse(req.data.data)
            this.appService.updateDomain(legacyDto)
            await this.appService.setCurrentDomain(legacyDto)

            simpleNotification('success', `${domain.displayName} was successfully updated`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return { ok, data: domain! }
    }

    /**
     * True PUT request for updating/replacing Domain entity. Uses new Domain Model.
     *
     * @param domainId
     * @param domain
     * @param cancellationKey
     */
    public async putDomainById(
        domainId: number,
        domain: Domain,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<Domain>> {
        let ok = false
        let updatedDomain: Domain
        this.appService.setModuleLoading()

        try {
            const req = await axiosFetch(
                'put',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}`,
                    data: domain,
                },
                cancellationKey,
            )

            ok = true
            updatedDomain = hydrateDomainModel(req.data.data)

            // V1 using DomainDto prior to updating app wide Domain Model types
            const legacyDto = DomainDto.fromApiResponse(req.data.data)
            this.appService.updateDomain(legacyDto)
            await this.appService.setCurrentDomain(legacyDto)

            simpleNotification('success', `${domain.displayName} was successfully updated`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return { ok, data: updatedDomain! }
    }

    public async fetchSubscriberPreferencesByDomainId(domainId: number, cancellationKey?: string): Promise<string[]> {
        let preferences: any[] = []

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/subscriber-preferences`,
                },
                cancellationKey,
            )

            preferences = req.data.data || []
        } catch (error) {}

        return preferences
    }

    public async fetchAttritionDomainId(
        domainId: number,
        showLoadingScreen?: boolean,
        cancellationKey?: string,
    ): Promise<number> {
        showLoadingScreen = showLoadingScreen !== false
        if (showLoadingScreen) this.appService.setModuleLoading()

        let attritionPct = 0

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/attrition`,
                },
                cancellationKey,
            )

            if (showLoadingScreen) this.appService.unsetModuleLoading()
            attritionPct = req.data.data.attritionPct
        } catch (error) {
            if (showLoadingScreen) this.appService.unsetModuleLoading()
        }

        return attritionPct
    }

    public async fetchActiveSubscriberEstimateForDomainId(
        domainId: number,
        returnType: 'count',
        opts?: IApiCallOptions,
    ): Promise<{ reach: number } | void>
    public async fetchActiveSubscriberEstimateForDomainId(
        domainId: number,
        returnType: 'count_by_channel',
        opts?: IApiCallOptions,
    ): Promise<{ reach: ReachByChannelResponse } | void>
    public async fetchActiveSubscriberEstimateForDomainId(
        domainId: number,
        returnType: EstimateReturnType,
        opts: IApiCallOptions = {},
    ): Promise<{ reach: ReachByChannelResponse | number } | void> {
        if (opts.showLoadingScreen) this.appService.setModuleLoading()

        const query = querystring.stringify({ return_type: returnType })

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/estimate-active-subscribers?${query}`,
                },
                opts.cancellationKey,
            )

            if (opts.showLoadingScreen) this.appService.unsetModuleLoading()
            return convertCase(req.data.data, 'snake') ?? 0
        } catch (error) {
            if (opts.showLoadingScreen) this.appService.unsetModuleLoading()
        }
    }

    public async fetchSegmentsByDomainId(
        domainId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<SegmentDto[]>> {
        let ok = false
        let data: SegmentDto[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) this.appService.setModuleLoading()
        const qsOpts = querystring.stringify(opts.query ?? {})

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/segments?${qsOpts}`,
                },
                opts.cancellationKey,
            )
            const { data: segments, ...resMeta } = res.data

            ok = true
            data = segments.map(SegmentDto.parse)
            meta = resMeta
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) this.appService.unsetModuleLoading()

        return { ok, data, meta }
    }

    public async fetchKeywordsByDomainId(
        domainId: number,
        opts: IApiCallOptions & { rawOrder?: boolean } = {},
    ): Promise<IServiceApiResponse<DomainKeyword[]>> {
        let ok = false
        let cancelled = false
        let keywords: DomainKeyword[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts.query || {})
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/keywords?${options}`,
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            keywords = data
            meta = resMeta

            if (!opts.rawOrder) {
                keywords.sort((a: DomainKeyword, b: DomainKeyword) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            }
        } catch (error) {
            if (isAxiosCancellation(error)) {
                cancelled = true
            }
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return {
            ok,
            data: keywords,
            meta,
            cancelled,
        }
    }

    public async addKeywordsToDomain(
        domainId: number,
        kwNames: string[],
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<DomainKeyword[]>> {
        let ok = false
        let cancelled = false
        let keywords: DomainKeyword[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts.query || {})
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/keywords?${options}`,
                    data: {
                        keywords: kwNames,
                    },
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            keywords = data
            meta = resMeta
        } catch (error) {
            cancelled = isAxiosCancellation(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return {
            ok,
            data: keywords,
            meta,
            cancelled,
        }
    }

    public async archiveKeywords(
        domainId: number,
        keywordIds: string[] | number[],
        opts: IApiCallOptions = {},
    ): Promise<any> {
        let ok = false
        let cancelled = false
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const res = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/keywords/bulk-archive`,
                    data: { keywordIds },
                },
                opts.cancellationKey,
            )

            ok = res.data.status === 'success'

            simpleNotification('success', `${keywordIds.length} Keywords successfully archived.`)
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return {
            ok,
            cancelled,
            meta,
        }
    }

    public async fetchKeywordDoc(domainId: number, cancellationKey?: string): Promise<void> {
        this.appService.setModuleLoading()

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/keywords/export`,
                },
                cancellationKey,
            )

            this.appService.unsetModuleLoading()

            const responseIsCSV = /text\/csv/i.test(req.request.getResponseHeader('content-type'))

            if (responseIsCSV) {
                fileDownload(req.data, `${req.filename || `${this.appState.currentDomain?.displayName} Keywords`}.csv`)
            }
        } catch (error) {
            handleResponseErrorMessage(error)
            this.appService.unsetModuleLoading()
        }
    }

    public async fetchPageTagsByDomainId(
        domainId: number,
        opts: IApiCallOptions & { rawOrder?: boolean } = {},
    ): Promise<IServiceApiResponse<DomainPageTag[]>> {
        let ok = false
        let cancelled = false
        let pageTags: DomainPageTag[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts.query || {})
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/page-tags?${options}`,
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            pageTags = data
            meta = resMeta

            if (!opts.rawOrder) {
                pageTags.sort((a: DomainPageTag, b: DomainPageTag) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            }
        } catch (error) {
            cancelled = isAxiosCancellation(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return {
            ok,
            data: pageTags,
            meta,
            cancelled,
        }
    }

    public async fetchItemGroupsByDomainId(
        domainId: number,
        opts: IApiCallOptions & { rawOrder?: boolean } = {},
    ): Promise<IServiceApiResponse<EcommItemGroup[]>> {
        let ok = false
        let cancelled = false
        let itemGroups: EcommItemGroup[] = []
        let meta: any = {}

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts.query || {})
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/item-groups?${options}`,
                },
                opts.cancellationKey,
            )

            const { data, ...resMeta } = req.data

            ok = true
            itemGroups = data
            meta = resMeta

            if (!opts.rawOrder) {
                itemGroups.sort((a: EcommItemGroup, b: EcommItemGroup) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
                )
            }
        } catch (error) {
            cancelled = isAxiosCancellation(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return {
            ok,
            data: itemGroups,
            meta,
            cancelled,
        }
    }

    public async estimateSegmentReach(
        domainId: number,
        segmentIds: number[],
        excludedSegmentIds: number[],
        targetedChannels: string[],
        returnType: 'count_by_channel',
        requiredAudienceTypes: RequiredAudienceType[],
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<{ [key in DeliveryChannel]: number } | undefined>>
    public async estimateSegmentReach(
        domainId: number,
        segmentIds: number[],
        excludedSegmentIds: number[],
        targetedChannels: string[],
        returnType: 'count',
        requiredAudienceTypes: RequiredAudienceType[],
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<number | undefined>>
    public async estimateSegmentReach(
        domainId: number,
        segmentIds: number[],
        excludedSegmentIds: number[],
        targetedChannels: string[],
        returnType: EstimateReturnType,
        requiredAudienceTypes: RequiredAudienceType[],
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<number | { [key in DeliveryChannel]: number } | undefined>> {
        let ok = false
        let cancelled = false
        let estimate: number | { [key in DeliveryChannel]: number } | undefined

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/estimate-reach`,
                    data: {
                        segmentIds,
                        excludedSegmentIds,
                        targetedChannels,
                        returnType,
                        requiredAudienceTypes,
                    },
                },
                opts.cancellationKey,
            )

            ok = true
            estimate = convertCase(req.data.data?.reach, 'snake') ?? 0
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        return { ok, data: estimate, cancelled }
    }

    public async getPreviewRegistrationCount(
        domainId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<number>> {
        let ok = false
        let count: number = 0
        let cancelled = false

        const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-preview-registration-count`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            ok = true
            count = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        return { ok, data: count, cancelled }
    }
}
