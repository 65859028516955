import * as React from 'react'
import classnames from 'classnames'
import { Redirect, Route as ReactRoute } from 'react-router-dom'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from './components/better-component/better-component'
import { AppService } from './services/index'
import { AppState } from './stores/app'
import { Route, Switch } from './components/router'
import { DomainFeature } from './features/domains'
import './pufferfish.scss'
import { delay, fetchDomain, fetchFlags } from './_utils/utils'
import { IAppInjectedProps } from './components/better-component/better-component'
import { PlatformMessageBar } from './components/platform-message-bar/platform-message-bar'
import { GAHelper } from './components/ga-helper/ga-helper'
import { RegisterPreviewDevice } from './features/register-preview-device/register-preview-device'
import { User } from './features/post-auth/user'
import IntegrationsOauthInterceptor from './features/integrations-oauth-interceptor/integrations-oauth-interceptor'
import { synchronizeDomainStates } from './_utils/domain'
import { Error404 } from './exceptions/error-404'
import Sidebar from './components/sidebar/sidebar'
import { OrganizationsFeature } from './features/organizations/organizations'
import { getPathEntityId } from './_utils/get-path-entity-id'
import { AppActionContext } from './enums/app-action-context'
import { PlatformUserContext } from './providers/user-provider/user-provider'
import { PlatformUserDomainRecord } from './models/domain/platform-user-domain-record'
import { DomainDto } from './dtos/domain'

interface IProps {}

interface IState {}

@observer
export class Pufferfish extends BetterComponent<IProps, IState> {
    public static contextType = PlatformUserContext
    public context!: React.ContextType<typeof PlatformUserContext>

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.appService.attachHistoryContainer(this.injectedProps.history)

        delay(() => this.intiliazeGdprCookieConsent(), 1)
    }

    public componentDidMount() {}

    public componentDidUpdate(prevProps: IProps & IAppInjectedProps) {
        if (this.injectedProps.location !== prevProps.location) {
            this.onRouteChanged(prevProps)

            if (prevProps.history.action === 'POP') {
                this.appService.handleBackButtonNavigation(prevProps, this.injectedProps)
            }
        }
        // TODO: Fetch Flags every X

        if (this.context.authenticated) {
            this.appService.startFetchingPlatformMessages()
        }
    }

    public async onRouteChanged(_prevProps: IProps & IAppInjectedProps) {
        if (this.context.authenticated) {
            const pathDomainId = getPathEntityId('domain')
            const pathOrgId = getPathEntityId('organization')
            const currDomainId = this.appState.currentDomain?.id
            const currOrgId = this.appState.currentDomain?.accountId

            const isOrgRoot = /^\/?organizations\//.test(location.pathname)
            const isDomainRoot = /^\/?domains\//.test(location.pathname)

            /**
             * Update context based on initial path evaluation
             * This eval is faster than the below sync - better ux
             */
            if (isOrgRoot && !this.appState.inOrgContext) {
                this.appState.actionContext = AppActionContext.ORG
            } else if (isDomainRoot && this.appState.inOrgContext) {
                this.appState.actionContext = AppActionContext.DOMAIN
            }

            /**
             * Full path & entity evaluation ensures proper
             * domain | org settings for the app based on current route
             * and the current app state.
             *
             * actionContext is reset here as a failsafe if the sync
             * produces a different reported context.
             */
            if (
                (isOrgRoot && pathOrgId!.toString() !== currOrgId?.toString()) ||
                (isDomainRoot && pathDomainId!.toString() !== currDomainId?.toString())
            ) {
                const syncRes = await synchronizeDomainStates()
                this.appState.actionContext = syncRes.context // failsafe

                if (syncRes.domain) {
                    // set 404 if required
                    const has404Error = syncRes.error instanceof Error404
                    if (has404Error) {
                        this.appService.set404State()
                    }

                    // if org context: ensure synched domain belongs to
                    // current requested org
                    let domainUpdate: DomainDto | PlatformUserDomainRecord = syncRes.domain
                    if (isOrgRoot && domainUpdate.accountId.toString() !== pathOrgId!.toString()) {
                        domainUpdate =
                            this.appState.currentUserDomains?.find(
                                (d) => d.accountId.toString() === pathOrgId!.toString(),
                            ) ?? domainUpdate
                    }

                    await this.appService.setCurrentDomain(domainUpdate, false, has404Error)
                }
            }
        }
    }

    public intiliazeGdprCookieConsent() {
        const cc = (window as any).cookieconsent

        cc.initialise({
            container: this.appService.getAppContainer(),
            palette: {
                popup: {
                    background: '#0077a0',
                    text: '#f5f5f7',
                },
                button: {
                    background: '#f5f5f7',
                    text: '#0077a0',
                },
            },
            theme: 'classic',
            content: {
                dismiss: 'Got it!',
            },
        })
    }

    public render() {
        this.appService.customizeTabTitle()
        return this.appState.appIsLoading ? (
            ''
        ) : (
            <div className="module pufferfish">
                <div className="app-content-layout">
                    <Sidebar />

                    <div
                        className={classnames('app-content', {
                            'sidebar-open': !this.appState.sidebar.collapsed,
                            'org-context': this.appState.inOrgContext,
                        })}
                        ref={this.setContentContainer}
                    >
                        <PlatformMessageBar />

                        {this.appState.isAuthenticated && (
                            <>
                                <ReactRoute path="/" component={() => GAHelper(this.appState)} />
                                <ReactRoute
                                    path="/"
                                    component={() => {
                                        this.appService.customizeTabTitle()
                                        return null
                                    }}
                                />
                            </>
                        )}

                        <Switch>
                            <Route path="/register-preview-device" exact={true} component={RegisterPreviewDevice} />

                            <ReactRoute path="/integrations" exact={true} component={IntegrationsOauthInterceptor} />

                            <ReactRoute path="/domains/:domainId([0-9]+)" component={DomainFeature} />
                            <ReactRoute path="/organizations/:orgId([0-9]+)" component={OrganizationsFeature} />

                            <Route path="/users/:userId([\d]+)" exact={true} component={User} />

                            {/*
                                When Authenticated - Handle Default Route
                                - Causes redirect loop if user is not authenticated
                            */}
                            {this.context.authenticated && <Redirect from="/" exact={true} to={this.dashboardUrl} />}
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }

    protected get dashboardUrl(): string {
        return this.appService.routeWithinDomain('/dashboard', true)
    }

    protected get orgUrl(): string {
        return `/organizations/${this.appState.currentDomain?.accountId}`
    }

    protected setContentContainer = (element: any) => {
        this.appService.contentContainerRef = element
    }
}
